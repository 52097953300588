// @import "variables";


@font-face {
  font-family: "mona-sans";
  src: url('../../../assets/fonts/Mona-Sans.ttf') format("truetype");
}

* {
  font-family: 'mona-sans', sans-serif;
}