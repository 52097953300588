.skill-list {
    
    .sl-item {
        display: flex;
        margin-bottom: 18px;
    }

    .sl-item--kvp {
        justify-content: space-between;
        align-items: flex-start;
        gap: 0px 40px;
        flex-wrap: wrap;
    }



    .sl__skill {
        text-align: left;
        min-width: 45% !important;
        flex: 1;
    }

    .sl__tick {
        background-image: url(/assets/icons/one-os-circle-tick--dark.svg);
        height: 24px;
        aspect-ratio: 1 / 1;
    }


    .sl__level {
        min-width: 45% !important;
        padding: 3px 12px 2px 12px;
        background:  $rag-g;
        color: $white;
        text-align: right;
        white-space: nowrap;
        border-radius: 15px;

        display: flex;
        align-items: center;
        gap: 10px;


        &.sl__level--1 {
            background: $rag-g;
            color: $white;
        }

        &.sl__level--2 {
            color: $white;
        }

        &.sl__level--3 {
            color: $white;
        }

        &.sl__level--locked {
            padding: 0px 0px 0px 12px;
        }


    }

    .sl__edit {
        line-height: 1;
        position: relative;
    }


}


// Table design

#table {
    width: 100%;
}

#table td {
    padding-bottom: 10px;
    width: 100%;
}

#table td:last-of-type {
    text-align: right;
}

#table td:nth-child(2) {
    text-align: right;
}

#table td:not(:last-of-type) {
    padding-right: 10px;
    word-wrap: break-word;
}
.ss__mobile #table td:not(:last-of-type) {
    padding-right: 4px;
}