// @import "variables";

* {
    box-sizing: border-box!important;
    margin: 0px;
    padding: 0px;
    color: inherit;
    font-size: inherit;
    scroll-behavior: smooth;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html:not(.lite) {
  height: 100%;
}

body:not(.lite) {
  height: 100%;
  background-color: var(--white);
  color: $font-color;
}
body.lite {
  background-color: var(--white);
  color: $font-color;
}

p {
  font-size: 18px;
  line-height: 1.4;
  font-weight: normal;
}

.soft-load,
.soft-load * {
  opacity: 1;
  animation: soft-loading;
  animation-duration: 0.2125s;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-timing-function: linear;

  -webkit-animation-fill-mode: initial;
  animation-fill-mode: initial;
}

@keyframes soft-loading {
  0% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

*::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-track {
  background-color: #dddddd;
}

*::-webkit-scrollbar-thumb {
  background-color: #3452e2;
  border-radius: 5px;
  border: 1px solid #dddddd;
}


h1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 20px;
}

h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 20px;
}

h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 20px;
}