
.theme-pe .l-footer {
    background-color: #000000;
    padding: 18px;
    color: #ffffff;
}
.theme-tp .l-footer {
    background-color: #000000;
    padding: 18px;
    color: #ffffff;
}
.theme-fleet .l-footer {
    background-color: #ec692f;
    padding: 18px;
    color: #ffffff;
}