@import "variables";

.mt-bl,
.child-mt-bl > * {
  margin-top: $baseline !important;
}

.mb-bl,
.child-mb-bl > * {
  margin-bottom: $baseline !important;
}

.mv-bl,
.my-bl,
.child-mv-bl > *,
.child-my-bl > * {
  margin-top: $baseline !important;
  margin-bottom: $baseline !important;
}

/* Auto margins */
.m-auto,
.ma-auto,
.child-ma-auto > *,
.child-ma-auto > * {
  margin: auto !important;
}

.mt-auto,
.child-ma-auto > * {
  margin-top: auto !important;
}

.mr-auto,
.child-ma-auto > * {
  margin-right: auto !important;
}

.mb-auto,
.child-ma-auto > * {
  margin-bottom: auto !important;
}

.ml-auto,
.child-ma-auto > * {
  margin-left: auto !important;
}

.mh-auto,
.mx-auto,
.child-mh-auto > *,
.child-mx-auto > * {
  /* Horizontal / x-axis */
  margin-left: auto !important;
  margin-right: auto !important;
}

.mv-auto,
.my-auto,
.child-mv-auto > *,
.child-my-auto > * {
  /* Vertical / y-axis */
  margin-top: auto !important;
  margin-bottom: auto !important;
}

/* Based on the standard grid spacing */
.m-grid,
.ma-grid,
.child-m-grid > *,
.child-ma-grid > * {
  margin: $baseline $gutter $baseline $gutter !important;
}

.mt-grid,
.child-mt-grid > * {
  margin-top: $baseline !important;
}

.mr-grid,
.child-mr-grid > * {
  margin-right: $gutter !important;
}

.mb-grid,
.child-mb-grid > * {
  margin-bottom: $baseline !important;
}

.ml-grid,
.child-ml-grid > * {
  margin-left: $gutter !important;
}

.mh-grid,
.mx-grid,
.child-mh-grid > *,
.child-mx-grid > * {
  /* Horizontal / x-axis */
  margin-left: $gutter !important;
  margin-right: $gutter !important;
}

.mv-grid,
.my-grid,
.child-mv-grid > *,
.child-my-grid > * {
  /* Vertical / y-axis */
  margin-top: $baseline !important;
  margin-bottom: $baseline !important;
}

/* Custom spacing - see root */
.m-custom,
.ma-custom,
.child-m-custom > *,
.child-ma-custom > * {
  margin: $panel-custom $panel-custom $panel-custom $panel-custom !important;
}

.mt-custom,
.child-mt-custom > * {
  margin-top: $panel-custom !important;
}

.mr-custom,
.child-mr-custom > * {
  margin-right: $panel-custom !important;
}

.mb-custom,
.child-mb-custom > * {
  margin-bottom: $panel-custom !important;
}

.ml-custom,
.child-ml-custom > * {
  margin-left: $panel-custom !important;
}

.mh-custom,
.mx-custom,
.child-mh-custom > *,
.child-mx-custom > * {
  /* Horizontal / x-axis */
  margin-left: $panel-custom !important;
  margin-right: $panel-custom !important;
}

/* Custom */
.mv-custom,
.my-custom,
.child-mv-custom > *,
.child-my-custom > * {
  /* Vertical / y-axis */
  margin-top: $panel-custom !important;
  margin-bottom: var(--baspanel-customeline) !important;
}

/* Zero margins */
.m-0,
.ma-0,
.child-m-0 > *,
.child-ma-0 > * {
  margin: 0px !important;
}

.mt-0,
.child-mt > * {
  margin-top: 0px !important;
}

.mr-0,
.child-mr-0 > * {
  margin-right: 0px !important;
}

.mb-0,
.child-mb-0 > * {
  margin-bottom: 0px !important;
}

.ml-0,
.child-ml-0 > * {
  margin-left: 0px !important;
}

.mh-0,
.mx-0,
.child-mh-0 > *,
.child-mx-0 > * {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mv-0,
.my-0,
.child-mv-0 > *,
.child-my-0 > * {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

/* Single pixel */
.m-1,
.ma-1,
.child-m-1 > *,
.child-ma-1 > * {
  margin: 1px !important;
}

.mt-1,
.child-mt-1 > * {
  margin-top: 1px !important;
}

.mr-1,
.child-mr-1 > * {
  margin-right: 1px !important;
}

.mb-1,
.child-mb-1 > * {
  margin-bottom: 1px !important;
}

.ml-1,
.child-ml-1 > * {
  margin-left: 1px !important;
}

.mh-1,
.mx-1,
.child-mh-1 > *,
.child-mx-1 > * {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.mv-1,
.my-1,
.child-mv-1 > *,
.child-my-1 > * {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

/* Two pixel */
.m-2,
.ma-2,
.child-m-2 > *,
.child-ma-2 > * {
  margin: 2px !important;
}

.mt-2,
.child-mt-2 > * {
  margin-top: 2px !important;
}

.mr-2,
.child-mr-2 > * {
  margin-right: 2px !important;
}

.mb-2,
.child-mb-2 > * {
  margin-bottom: 2px !important;
}

.ml-2,
.child-ml-2 > * {
  margin-left: 2px !important;
}

.mh-2,
.mx-2,
.child-mh-2 > *,
.child-mx-2 > * {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.mv-2,
.my-2,
.child-mv-2 > *,
.child-my-2 > * {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

/* Three pixel */
.m-3,
.ma-3,
.child-m-3 > *,
.child-ma-3 > * {
  margin: 3px !important;
}

.mt-3,
.child-mt-3 > * {
  margin-top: 3px !important;
}

.mr-3,
.child-mr-3 > * {
  margin-right: 3px !important;
}

.mb-3,
.child-mb-3 > * {
  margin-bottom: 3px !important;
}

.ml-3,
.child-ml-3 > * {
  margin-left: 3px !important;
}

.mh-3,
.mx-3,
.child-mh-3 > *,
.child-mx-3 > * {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.mv-3,
.my-3,
.child-mv-3 > *,
.child-my-3 > * {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

/* Five pixel */
.m-5,
.ma-5,
.child-5 > *,
.child-ma-5 > * {
  margin: 5px !important;
}

.mt-5,
.child-mt-5 > * {
  margin-top: 5px !important;
}

.mr-5,
.child-mr-5 > * {
  margin-right: 5px !important;
}

.mb-5,
.child-mb-5 > * {
  margin-bottom: 5px !important;
}

.ml-5,
.child-ml-5 > * {
  margin-left: 5px !important;
}

.mh-5,
.mx-5,
.child-mh-5 > *,
.child-mx-5 > * {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mv-5,
.my-5,
.child-mv-5 > *,
.child-my-5 > * {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

/* Eight pixel */
.m-8,
.ma-8,
.child-m-8 > *,
.child-ma-8 > * {
  margin: 8px !important;
}

.mt-8,
.child-mt-8 > * {
  margin-top: 8px !important;
}

.mr-8,
.child-mr-8 > * {
  margin-right: 8px !important;
}

.mb-8,
.child-mb-8 > * {
  margin-bottom: 8px !important;
}

.ml-8,
.child-ml-8 > * {
  margin-left: 8px !important;
}

.mh-8,
.mx-8,
.child-mh-8 > *,
.child-mx-8 > * {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.mv-8,
.my-8,
.child-mv-8 > *,
.child-my-8 > * {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

/* Ten pixel */
.m-10,
.ma-10,
.child-m-10 > *,
.child-ma-10 > * {
  margin: 10px !important;
}

.mt-10,
.child-mt-10 > * {
  margin-top: 10px !important;
}

.mr-10,
.child-mr-10 > * {
  margin-right: 10px !important;
}

.mb-10,
.child-mb-10 > * {
  margin-bottom: 10px !important;
}

.ml-10,
.child-ml-10 > * {
  margin-left: 10px !important;
}

.mh-10,
.mx-10,
.child-mh-10 > *,
.child-mx-10 > * {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mv-10,
.my-10,
.child-mv-10 > *,
.child-my-10 > * {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

/* Twelve pixel */
.m-12,
.ma-12,
.child-m-12 > *,
.child-ma-12 > * {
  margin: 12px !important;
}

.mt-12,
.child-mt-12 > * {
  margin-top: 12px !important;
}

.mr-12,
.child-mr-12 > * {
  margin-right: 12px !important;
}

.mb-12,
.child-mb-12 > * {
  margin-bottom: 12px !important;
}

.ml-12,
.child-ml-12 > * {
  margin-left: 12px !important;
}

.mh-12,
.mx-12,
.child-mh-12 > *,
.child-mx-12 > * {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.mv-12,
.my-12,
.child-mv-12 > *,
.child-my-12 > * {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

/* Sixteen pixel */
.m-16,
.ma-16,
.child-m-16 > *,
.child-ma-16 > * {
  margin: 16px !important;
}

.mt-16,
.child-mt-16 > * {
  margin-top: 16px !important;
}

.mr-16,
.child-mr-16 > * {
  margin-right: 16px !important;
}

.mb-16,
.child-mb-16 > * {
  margin-bottom: 16px !important;
}

.ml-16,
.child-ml-16 > * {
  margin-left: 16px !important;
}

.mh-16,
.mx-16,
.child-mh-16 > *,
.child-mx-16 > * {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.mv-16,
.my-16,
.child-mv-16 > *,
.child-my-16 > * {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

/* Paddings using typographic line height or baseline */
.pt-bl,
.child-pt-bl > * {
  padding-top: $baseline !important;
}

.pb-bl,
.child-pb-bl > * {
  padding-bottom: $baseline !important;
}

.pv-bl,
.py-bl,
.child-pv-bl > *,
.child-py-bl > * {
  padding-top: $baseline !important;
  padding-bottom: $baseline !important;
}

/* Based on the standard grid spacing. */
.p-grid,
.pa-grid,
.child-p-grid > *,
.child-pa-grid > * {
  padding: $baseline $gutter $baseline $gutter !important;
}

.pt-grid,
.child-pt-grid > * {
  padding-top: $baseline !important;
}

.pr-grid,
.child-pr-grid > * {
  padding-right: $gutter !important;
}

.pb-grid,
.child-pb-grid > * {
  padding-bottom: $baseline !important;
}

.pl-grid,
.child-pl-grid > * {
  padding-left: $gutter !important;
}

.ph-grid,
.px-grid,
.child-ph-grid > *,
.child-px-grid > * {
  /* Horizontal / x-axis */
  padding-left: $gutter !important;
  padding-right: $gutter !important;
}

.pv-grid,
.py-grid,
.child-OO-grid > *,
.child-OO-grid > * {
  /* Vertical / y-axis */
  padding-top: $baseline !important;
  padding-bottom: $baseline !important;
}

/* Custom spacing - see root */
.p-custom,
.pa-custom,
.child-p-custom > *,
.child-pa-custom > * {
  padding: $panel-custom $panel-custom $panel-custom $panel-custom !important;
}

.pt-custom,
.child-pt-custom > * {
  padding-top: $panel-custom !important;
}

.pr-custom,
.child-pr-custom > * {
  padding-right: $panel-custom !important;
}

.pb-custom,
.child-pb-custom > * {
  padding-bottom: $panel-custom !important;
}

.pl-custom,
.child-pl-custom > * {
  padding-left: $panel-custom !important;
}

.ph-custom,
.px-custom,
.child-ph-custom > *,
.child-px-custom > * {
  /* Horizontal / x-axis */
  padding-left: $panel-custom !important;
  padding-right: $panel-custom !important;
}

/* Custom */
.pv-custom,
.py-custom,
.child-pv-custom > *,
.child-py-custom > * {
  /* Vertical / y-axis */
  padding-top: $panel-custom !important;
  padding-bottom: $panel-custom !important;
}

/* Zero paddings */
.p-0,
.pa-0,
.child-p-0 > *,
.child-pa-0 > * {
  padding: 0px !important;
}

.pt-0,
.child-pt-0 > * {
  padding-top: 0px !important;
}

.pr-0,
.child-pr-0 > * {
  padding-right: 0px !important;
}

.pb-0,
.child-pb-0 > * {
  padding-bottom: 0px !important;
}

.pl-0,
.child-pl-0 > * {
  padding-left: 0px !important;
}

.ph-0,
.px-0,
.child-ph-0 > *,
.child-px-0 > * {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pv-0,
.py-0,
.child-pv-0 > *,
.child-py-0 > * {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* Single pixel */
.p-1,
.pa-1,
.child-p-1 > *,
.child-pa-1 > * {
  padding: 1px !important;
}

.pt-1,
.child-pt-1 > * {
  padding-top: 1px !important;
}

.pr-1,
.child-pr-1 > * {
  padding-right: 1px !important;
}

.pb-1,
.child-pb-1 > * {
  padding-bottom: 1px !important;
}

.pl-1,
.child-pl-1 > * {
  padding-left: 1px !important;
}

.ph-1,
.px-1,
.child-ph-1 > *,
.child-px-1 > * {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.pv-1,
.py-1,
.child-pv-1 > *,
.child-py-1 > * {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

/* Two pixel */
.p-2,
.pa-2,
.child-p-2 > *,
.child-pa-2 > * {
  padding: 2px !important;
}

.pt-2,
.child-pt-2 > * {
  padding-top: 2px !important;
}

.pr-2,
.child-pr-2 > * {
  padding-right: 2px !important;
}

.pb-2,
.child-pb-2 > * {
  padding-bottom: 2px !important;
}

.pl-2,
.child-pl-2 > * {
  padding-left: 2px !important;
}

.ph-2,
.px-2,
.child-ph-2 > *,
.child-px-2 > * {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.pv-2,
.py-2,
.child-pv-2 > *,
.child-py-2 > * {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

/* Three pixel */
.p-3,
.pa-3,
.child-p-3 > *,
.child-pa-3 > * {
  padding: 3px !important;
}

.pt-3,
.child-pt-3 > * {
  padding-top: 3px !important;
}

.pr-3,
.child-pr-3 > * {
  padding-right: 3px !important;
}

.pb-3,
.child-pb-3 > * {
  padding-bottom: 3px !important;
}

.pl-3,
.child-pl-3 > * {
  padding-left: 3px !important;
}

.ph-3,
.px-3,
.child-ph-3 > *,
.child-px-3 > * {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.pv-3,
.py-3,
.child-pv-3 > *,
.child-py-3 > * {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

/* Five pixel */
.p-5,
.pa-5,
.child-p-5 > *,
.child-pa-5 > * {
  padding: 5px !important;
}

.pt-5,
.child-pt-5 > * {
  padding-top: 5px !important;
}

.pr-5,
.child-pr-5 > * {
  padding-right: 5px !important;
}

.pb-5,
.child-pb-5 > * {
  padding-bottom: 5px !important;
}

.pl-5,
.child-pl-5 > * {
  padding-left: 5px !important;
}

.ph-5,
.px-5,
.child-ph-5 > *,
.child-px-5 > * {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.pv-5,
.py-5,
.child-pv-5 > *,
.child-py-5 > * {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

/* Eight pixel */
.p-8,
.pa-8,
.child-p-8 > *,
.child-pa-8 > * {
  padding: 8px !important;
}

.pt-8,
.child-OptO-8 > * {
  padding-top: 8px !important;
}

.pr-8,
.child-pr-8 > * {
  padding-right: 8px !important;
}

.pb-8,
.child-pb-8 > * {
  padding-bottom: 8px !important;
}

.pl-8,
.child-pl-8 > * {
  padding-left: 8px !important;
}

.ph-8,
.px-8,
.child-ph-8 > *,
.child-px-8 > * {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.pv-8,
.py-8,
.child-pv-8 > *,
.child-py-8 > * {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

/* Ten pixel */
.p-10,
.pa-10,
.child-p-10 > *,
.child-pa-10 > * {
  padding: 10px !important;
}

.pt-10,
.child-OptO-10 > * {
  padding-top: 10px !important;
}

.pr-10,
.child-pr-10 > * {
  padding-right: 10px !important;
}

.pb-10,
.child-pb-10 > * {
  padding-bottom: 10px !important;
}

.pl-10,
.child-pl-10 * {
  padding-left: 10px !important;
}

.ph-10,
.px-10,
.child-ph-10 > *,
.child-px-10 > * {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pv-10,
.py-10,
.child-pv-10 > *,
.child-py-10 > * {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

/* Twelve pixel */
.p-12,
.pa-12,
.child-p-12 > *,
.child-pa-12 > * {
  padding: 12px !important;
}

.pt-12,
.child-OptO-12 > * {
  padding-top: 12px !important;
}

.pr-12,
.child-pr-12 > * {
  padding-right: 12px !important;
}

.pb-12,
.child-pb-12 > * {
  padding-bottom: 12px !important;
}

.pl-12,
.child-pl-12 > * {
  padding-left: 12px !important;
}

.ph-12,
.px-12,
.child-ph-12 > *,
.child-px-12 > * {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.pv-12,
.py-12,
.child-pv-12 > *,
.child-py-12 > * {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

/* Sixteen pixel */
.p-16,
.pa-16,
.child-p-16 > *,
.child-pa-16 > * {
  padding: 16px !important;
}

.pt-16,
.child-OptO-16 > * {
  padding-top: 16px !important;
}

.pr-16,
.child-pr-16 > * {
  padding-right: 16px !important;
}

.pb-16,
.child-pb-16 > * {
  padding-bottom: 16px !important;
}

.pl-16,
.child-pl-16 > * {
  padding-left: 16px !important;
}

.ph-16,
.px-16,
.child-ph-16 > *,
.child-px-16 > * {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.pv-16,
.py-16,
.child-pv-16 > *,
.child-py-16 > * {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
