// :root {
// --brand-primary: red;
// }
@import "variables";
$rgb: $brand-primary;
// $rgb: red;

// $red: #{red($rgb)};
// $green: green($brand-primary);
// $blue: blue($brand-primary);

// $rgb:

.main-loader {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100% !important;
    width: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
    background: $brand-primary;
    z-index: 1035;
    opacity: 1;
    transition: opacity 1s;
    cursor: wait;
}

.main-loading {
    color: $rgb;
    // --brand-color1: 116, 80, 242;
    --border-width: 20%;
    --mask: radial-gradient(farthest-side, transparent calc(100% - var(--border-width) - 0.5px), #000000 calc(100% - var(--border-width) + 0.5px));
    //
    display: block;
    min-height: 20px;
    min-width: 20px;
    height: 100%;
    aspect-ratio: 1 / 1;
    -webkit-mask: var(--mask);
    mask: var(--mask);
    border-radius: 50%;
    // background: linear-gradient(to top, rgba($rgb, 1), rgba($rgb, .5)) 100% 0 / 50% 100% no-repeat, linear-gradient(rgba($rgb, 0.5) 50%, transparent 95%) 0 0 / 50% 100% no-repeat;
    // background: linear-gradient(to top, rgba(var(--brand-primary), 1), rgba(var(--brand-primary), .5)) 100% 0 / 50% 100% no-repeat, linear-gradient(rgba(var(--brand-primary), 0.5) 50%, transparent 95%) 0 0 / 50% 100% no-repeat;
    // background: linear-gradient(to top, rgba($brand-primary, 1), rgba($brand-primary, .5)) 100% 0 / 50% 100% no-repeat, linear-gradient(rgba($brand-primary, 0.5) 50%, transparent 95%) 0 0 / 50% 100% no-repeat;
    background: linear-gradient(to top, var(--brand-primary), var(--brand-primary)) 100% 0 / 50% 100% no-repeat, linear-gradient(var(--brand-primary) 50%, transparent 95%) 0 0 / 50% 100% no-repeat;
    // background: $brand-primary;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {}

    100% {
        transform: rotate(360deg);
    }
}