:root {
  --gap: 10px;
}

.pagelet {
  display: flex;
  gap: var(--gap);
}



/*
Orientation (row by default)
*/
.pagelet--stacked {
  flex-direction: column;
}

/*
Flow
*/

.pagelet .content {
  flex: 1;
}

/*
Flow
*/

.pagelet--flow:after,
.pagelet--flow-l:after,
.pagelet--flow-r:after {
  content: "";
  display: table;
  clear: both;
}

.pagelet--flow,
.pagelet--flow-l {
  display: block;

  .media {
    float: left;
    margin: 0px var(--gap) calc(var(--gap) / 2) 1px;
  }
}

.pagelet--flow-r {
  display: block;

  .media {
    float: right;
    margin: 0px 0px calc(var(--gap) / 2) var(--gap);
  }
}


/*
Alignment - Depends if stacked
*/

.pagelet--centered {
  align-items: center;
}
.pagelet--center-all {
  align-items: center;
  text-align: center;
}
/* ---------- */
.pagelet--right {
  flex-direction: row-reverse;
}
.pagelet--right-all {
  flex-direction: row-reverse;
}
.pagelet--right-all > * {
  flex-direction: row-reverse;
  text-align: right; 
}
/*
----------
*/
.pagelet--stacked.pagelet--centered {
  justify-content: center;
}
.pagelet--stacked.pagelet--center-all {
  align-items: center;
}
.pagelet--stacked.pagelet--center-all  > * {
  justify-content: center;
  text-align: center;
}
/* ---------- */
.pagelet--stacked.pagelet--right {
  flex-direction: column;
  align-items: flex-end;
}
.pagelet--stacked.pagelet--right-all {
  flex-direction: column;
  align-items: flex-end;
}
.pagelet--stacked.pagelet--right-all > * {
  text-align: right;
}

.pagelet--justify > * {
  text-align: justify;
}


/* wrapping */
.pagelet--nowrap {
  white-space: nowrap;
}

.pagelet--nowrap .content {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pagelet--responsive {
  flex-wrap: wrap;
}