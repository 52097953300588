// @use "variables";

.badge {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.badge--clickable {
    transform: scale(0.98);
    white-space: nowrap;
    cursor: pointer;
    
    &:hover {
        transform: scale(1);
    }
}


.badge--bordered {
    border: 3px solid #fff;
    outline: 7px solid rgba($brand-primary-r, $brand-primary-g, $brand-primary-b, 0.4);
}


// Colours

.badge--white {
    background-color: #ffffff;
}


// Sizes

.badge--24 {
    height: 24px;
    width: 24px;
    min-width: 24px;
    font-size: 12px;
}
.badge--32 {
    height: 32px;
    width: 32px;
    min-width: 32px;
    font-size: 12px;
}
.badge--48 {
    height: 48px;
    width: 48px;
    min-width: 48px;
    font-size: 12px;
}
.badge--64 {
    height: 64px;
    width: 64px;
    min-width: 64px;
    font-size: 16px;
}
.badge--96 {
    height: 96px;
    width: 96px;
    min-width: 96px;
    font-size: 24px;
}
.badge--100 {
    height: 100px;
    width: 100px;
    min-width: 100px;
    font-size: 25px;
}