body {

    &.modal-window--open {
        // Note: body overflow is hidden to hide main scrollbar when modal window is open.
        overflow: hidden;
        -ms-overflow-style: none;
    }

    &.modal-window--scrollfix {
        margin-right: 6px;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 6px;
            background: #f5f5f5;
            z-index: 1;
        }
    }
}
