// @import "variables";

.icon {
    height: $default-icon-size;
    width: $default-icon-size;
    cursor: inherit;
}

.icon--pointer {
    cursor: pointer;
}

.icon-100pc {
    height: 100%;
    width: 100%;
}

.icon-8 {
    height: 8px;
    width: 8px;
}

.icon-10 {
    height: 10px;
    width: 10px;
}

.icon-12 {
    height: 12px;
    width: 12px;
}

.icon-16 {
    height: 16px;
    width: 16px;
}

.icon-20 {
    height: 20px;
    width: 20px;
}

.icon-24 {
    height: 24px;
    width: 24px;
}

.icon-32 {
    height: 32px;
    width: 32px;
}

.icon-48 {
    height: 48px;
    width: 48px;
}

.icon-50 {
    height: 50px;
    width: 50px;
}

.icon-60 {
    height: 60px;
    width: 60px;
}

.icon-64 {
    height: 64px;
    width: 64px;
}

.icon-96 {
    height: 96px;
    width: 96px;
}

.icon-100 {
    height: 100px;
    width: 100px;
}


// Percentages 
.icon-10pc {
    height: 10%;
    width: 10%;
}

.icon-20pc {
    height: 20%;
    width: 20%;
}

.icon-25pc {
    height: 25%;
    width: 25%;
}

.icon-30pc {
    height: 30%;
    width: 30%;
}

.icon-33pc {
    height: 33.3%;
    width: 33.3%;
}

.icon-40pc {
    height: 40%;
    width: 40%;
}

.icon-50pc {
    height: 50%;
    width: 50%;
}

.icon-60pc {
    height: 60%;
    width: 60%;
}

.icon-66pc {
    height: 66.6%;
    width: 66.6%;
}

.icon-70pc {
    height: 70%;
    width: 70%;
}

.icon-75pc {
    height: 75%;
    width: 75%;
}

.icon-80pc {
    height: 80%;
    width: 80%;
}

.icon-90pc {
    height: 90%;
    width: 90%;
}

.icon-100pc {
    height: 100%;
    width: 100%;
}

.icon--destructive {
    &:hover {
        &:before {
            content: "";
            position: absolute;
            height: 1px;
            width: 1px;
            background-color: transparent;
            z-index: -1;
            animation-name: btn-destruct;
            animation-duration: 0.8s;
            animation-iteration-count: 1;
            border-radius: 50%;
            opacity: 0.5;
        }
    }
}

@keyframes btn-destruct {
    from {
        outline: 0px solid #df2f1f;
    }

    to {
        outline: 100px solid transparent;
    }
}