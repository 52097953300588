
.toggle {
  cursor: pointer;
  display: inline-block;
  flex: none;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-switch {
  display: inline-block;
  border-radius: 16px;
  width: 50px;
  height: 24px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
  //
  background: $form-element--empty;
  border: 1px solid $form-element--border;
}

.toggle-switch:before,
.toggle-switch:after {
  content: "";
}

.toggle-switch:before {
  display: block;
  background: $white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 2px;
  left: 3px;
  transition: left 0.25s;
  //
  background-color: $form-element--active;
  width: 17px;
  height: 17px;
}

.toggle:hover .toggle-switch:before {
  background-color: $form-element--active;
}

.toggle:hover .toggle-checkbox:checked + .toggle-switch:before {
  background: $white;
}

.toggle-checkbox:checked + .toggle-switch {
  background-color: $form-element--active;
  border: 1px solid transparent;
}

.toggle-checkbox:checked + .toggle-switch:before {
  background: $form-element--empty;
  left: 28px;
}

.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
}
