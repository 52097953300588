.user-info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 12px;
}

.user-info__badge {
    position: relative;
    display: inline-flex;
    aspect-ratio: 1/1;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    user-select: none;
    overflow: hidden;
    width: 24px;
    font-size: 12px;
    font-weight: 600;
}

.user-info__name {
    color: $white;
}

.user-badge--24 {
    width: 24px;
    font-size: 12px;
    font-weight: 600;
}

.user-badge--32 {
    width: 32px;
    font-size: 12px;
    font-weight: 600;
}

.user-badge--48 {
    width: 48px;
    font-size: 18px;
    font-weight: 600;
}

.user-badge--64 {
    width: 64px;
    font-size: 18px;
    font-weight: 600;
}


// .theme-pe .user-info__badge {
//     background-color: $brand-secondary;
// }

// .theme-tp .user-info__badge {
//     background-color: $brand-secondary;
// }

// .theme-fleet .user-info__badge {
//     background-color: #ffffff;
//     background-color: #777777;
// }

.user-info__badge {
    background-color: $brand-secondary;
}