// @import "variables";

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    //
    min-height: $mobile-minimum-target-size;
    padding: 12px 20px;
    background-color: $button-bg-color;
    color: $button-text-color;
    border-radius: $border-radius-element;
    border: $button-border-weight solid $button-border-color;
    font-size: $button-font-size;
    line-height: $baseline;
    cursor: pointer;
    transition: $button-transition;
    user-select: none;
    transform: scale(0.98);
    white-space: nowrap;

    &:hover {
        background-color: $button-bg-color;
        transform: scale(1);
    }

    &:active {
        transform: scale(0.98);
    }

    &--disabled,
    &[disabled] {
        background-color: rgba(255, 255, 255, 0.5);
        background-color: rgba($button-bg-color, 0.15);
        cursor: not-allowed;
        transition: 0.7s all ease;
        color: $button-disabled-text-color;
        opacity: 0.5;

        &:hover {
            transform: scale(0.95);
            background-color: rgba($button-bg-color, 0.3);
        }
    }
}

.btn--progressive {
    background-color: $progressive;
    border: inherit;

    &:hover {
        background-color: $progressive;
    }
}

.btn--destructive {
    border: inherit;

    &:hover {
        animation-name: btn-destruct;
        animation-duration: 0.525s;
        animation-iteration-count: 1;
    }
}

.btn--ghost {
    border: 1px solid $button-bg-color;
    background: transparent;
    color: $button-bg-color;
    
    &:hover {
        background: $button-bg-color;
        color: $button-text-color;
    }
}

.btn--reduced {
    min-height: auto;
    padding: 6px 18px;
    border-radius: $border-radius-reduced;
}



@keyframes btn-destruct {
    from {
        outline: 0px solid $destructive;
    }

    to {
        outline: 100px solid transparent;
    }
}

// icons
// .btn--icon {
//   display: flex;
//   align-items: center;
//   gap: 12px;
// }

// .btn--icon i {
//   line-height: 0px;
// }

.btn .icon {
    height: 24px;
    width: 24px;
}

.btn--reduced .icon {
    height: 16px;
    width: 16px;
}

.btn--icon-right {
    // DOM: Please ensure the icon is before the label.
    flex-direction: row-reverse;
}