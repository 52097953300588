body {
    &.menu-open {
        overflow: hidden;
        -ms-overflow-style: none;
    }
}


// Header menu

.header-menu {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 40px;
    margin-left: auto;

    .hm__item {
        color: $white;
        cursor: pointer;
    }
}

@media (max-width: 576px) {
    .header-menu {
        display: none;
    }
}


.header-menu--expanded {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 18px;
}