// @import "variables";


.form {
  width: 100%;
  font-size: $form-input-font-size;
}

.form-field {
  display: flex;
  flex-direction: row;
  margin-bottom: 40px; // oneOs: Rendered as 40px
  flex-wrap: wrap;
}

.form-field--stacked {

  .field-label {
    display: block;
    position: relative;
    flex: 0 0 100%;
    min-width: 0px;
    margin: 0px 0px 10px 0px;
    font-size: $form-label-font-size;
    font-weight: $form-label-font-weight;
    text-transform: capitalize;
    line-height: 1.1;
  }

}

// Labels

.field-label {
  display: block;
  position: relative;
  flex: 0 0 180px;
  min-width: 180px;
  margin: calc($form-control-border-weight + $form-control-padding-y) 10px 8px 0px;
  font-size: $form-label-font-size;
  font-weight: $form-label-font-weight;
  text-transform: capitalize;
  line-height: 1.1;

  // oneOs: no colon.
  margin-bottom: 8px; // oneOs: Rendevred as 12px
  font-weight: $form-label-font-weight;
  font-size: $form-label-font-size;


  &.field-label:after {
    content: ":";
    position: absolute;
  }

  &.field-label--empty:after {
    content: "" !important;
  }

  &.field-label--right {
    text-align: right;
  }

  &.field-label--required {
    position: relative;
  }

  &.field-label--required:after {
    content: "*";
    margin-left: 4px;
    color: $rag-r;
  }
}


// Field group

.field-group {
  /* This is the right-hand side container, for grouping together related elements:
      (form-controls*n, description and validation) */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 400px;
  align-self: flex-start;
  flex-wrap: wrap;
  min-width: 0;

  >* {
    flex: 1 1 auto;
    align-items: center;
    justify-content: flex-start;
  }

  &.field-group--short {
    flex-grow: 0;
  }

  &.field-group--wrap {
    flex-wrap: wrap;
  }

  &.field-group--inline {
    flex-direction: row;
  }

  &.field-group--natural>* {
    flex: none;
  }

  &.field-group--error {
    input[type="text"] {
      border: 2px solid #F04150;
    }
  }
}

.field-group .field-group {
  align-items: baseline;
}

.field-group>.ddl {
  width: 100%;
}


// Form-control

.form-control {
  color: $form-input-font-color;
  background-color: $form-input-background-color;
  line-height: $form-input-line-height;
  font-size: $form-input-font-size;
  font-family: $font-family-display;
  width: 100%;
}




// Suplementary field information

.field-error {
  flex: 1;
  display: flex;
  gap: 8px;
  margin: 0px;
  color: $form-control-error-color;
  font-size: $form-control-error-size;
}

.field-description {
  flex: 1;
  margin: 0px;
  color: $form-control-desc-color;
  font-size: $form-control-desc-size;
}






// Radio button List

.radio-list {
  display: flex;
  flex-wrap: wrap;
  gap: 21px 40px;
  color: #4d4d4d;
  font-size: $form-input-font-size;
  font-weight: 500;

  &--stacked {
    flex-direction: column;
  }
}

.radio-list label {
  display: flex;
  align-items: center;
  gap: 16px
}


// Form fields
input[type="text"],
input[type="number"],
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="search"],
select,
textarea {
  outline: none;
  padding: $form-control-padding-y $form-control-padding-x $form-control-padding-y $form-control-padding-x;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 5%);
  border: $form-control-border-weight solid $form-control-border-color;
  resize: none;
  width: 100%;
  padding: 15px;

  @if ($rounded-forms==1) {
    border-radius: $border-radius-form-element;
  }

  &::placeholder {
    color: $form-input-placeholder-color;
    transition: all 0.25s ease;
  }

  &:hover::placeholder {
    color: rgba($form-input-placeholder-color, 1);
  }
}


.form-field--stacked app-form-select-dropdown {
  width: 100%;

  &>* {
      width: 100%!important;
  }
}