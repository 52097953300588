// @import "variables";


.l-layout {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.container {
    width: 100%;
    margin: auto;
    transition: 0.1s all ease-in-out;
    padding: 0px $gutter 20px $gutter;
}


@media (min-width: 576px) {
    .container {
        width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

@media (min-width: 1400px) {
    .container {
        width: 1366px;
    }
}

.container-fluid {
    padding-right: var(--gutter);
    padding-left: var(--gutter);
    margin-right: auto;
    margin-left: auto;
}