// @import "variables";

.btn-group {
  display: flex;
  flex: 1;
  gap: $element-spacing;
  margin-bottom: $baseline;
  flex-wrap: wrap;
  align-items: flex-start; // To prevent stretching when using btn-group--nowrap.
}

.btn-group--right {
  justify-content: end;
}

.btn-group--center {
  justify-content:center;
}

.btn-group--nowrap {
  flex-wrap: nowrap;
}
