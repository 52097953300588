#app-tooltip {
    background: $tooltip-background;
    border-radius: 4px;
    padding: 5px 10px 5px 10px;
    color: $tooltip-text;
    box-shadow: 0px 0px 30px $white, 0px 0px 30px $white;
    opacity: 0.5;
    animation-name: fade-in;
    animation-duration: 0.1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    transform: translateY(10px);
    user-select: none;
    pointer-events: none;
}

@keyframes fade-in {
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}



.app-tooltip--fit {
    max-width: fit-content;
}

.app-tooltip--xs {
    width: 50px;
}

.app-tooltip--sm {
    width: 100px;
}

.app-tooltip--md {
    width: 200px;
}

.app-tooltip--lg {
    width: 400px;
}

.app-tooltip--xl {
    width: 800px;
}