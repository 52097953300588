:root .theme-pe {
    --brand-primary: #3452e2;
    --brand-primary-r: 52;
    --brand-primary-g: 82;
    --brand-primary-b: 226;
    --brand-secondary: #fe8a02;
    --white: #ffffff;
    --black: #111111;
    --font-color: #393939;
    --link-color: #3452e2;

    // RAG
    --rag-r: #f04150;
    --rag-a: #dd7c0b;
    --rag-g: #208832;
    --rag-i: #3452e2;

    --tooltip-background: #404040;
    --tooltip-text: #ffffff;

    --critical:#e70000; //todo

    --form-element--active: #3452e2;
    --form-element--empty: #ffffff;
    --form-element--border: #747474;

    --button-bg-color: #3452e2;
    --button-text-color: #ffffff;
    --button-border-weight: 1px;
    --button-border-color: #3452e2;
    --button-font-size: $font-size;
    --button-transition: 0.125s background ease, 0.125s border ease;

    --button-icon-size: 20px;
    --button-icon-size-reduced: 16px;
    --button-icon-color: #ffffff;

    // Modal
    --modal-border-radius: 10px;
    --modal-background-color: #ffffff;
}