
// Breakpoints.
$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1400px;


$default-icon-size: 32px;

// Type
$font-family: "mona-sans", sans-serif;
$font-family-display: "mona-sans", sans-serif;
$line-height: 22px;

// Spacing
$gutter: 18px;
$element-spacing: 12px;
$panel-custom: 18px;


// Colors
$brand-primary: var(--brand-primary);
$brand-primary-r: var(--brand-primary-r);
$brand-primary-g: var(--brand-primary-g);
$brand-primary-b: var(--brand-primary-b);
$brand-secondary: var(--brand-secondary);
$white: var(--white);
$black: var(--black);
$font-color: var(--font-color);
$link-color: var(--link-color);
$rag-r: var(--rag-r);
$rag-a: var(--rag-a);
$rag-g: var(--rag-g);
$rag-i: var(--rag-i);

$progressive:var(--rag-g);
$destructive: var(--rag-r);


// Core
$mobile-minimum-target-size: 40px;
$baseline: 22px;
$font-size: 16px;
$font-size--reduced: 12px;
$max-line-width: 960px;
$max-line-width-splash: 600px;
$header-spacing: 20px;
$app-title-font-size: 20px;
$rounded-forms: 1;



// Buttons
$button-bg-color: var(--button-bg-color);
$button-text-color: var(--button-text-color);
$button-border-weight: var(--button-border-weight);
$button-border-color: var(--button-border-color);
$button-font-size: var(--button-font-size);
$button-transition: var(--button-transition);

// Buttons > Disablled
$button-disabled-text-color: var(--button-disabled-text-color);
// Buttons > Icons
$button-icon-size: var(--button-icon-size);
$button-icon-size-reduced: var(--button-icon-size-reduced);
$button-icon-color: var(--button-icon-color);

// Panels
$panel-bg-color:  var(--panel-bg-color);
$border-radius-container: 8px;
$border-radius-element: 8px;
$border-radius-form-element: 7px;
$border-radius-reduced: 4px;
$border-radius-custom: 80px;
$panel-box-shadow: var(--panel-box-shadow);
$panel-border: var(--panel-border);
$panel-padding: 15px;

$tooltip-background: var(--tooltip-background);
$tooltip-text: var(--tooltip-text);

// Modals
$modal-border-radius: var(--modal-border-radius);
$modal-background-colour: var(--modal-background-colour);

// Forms
$form-element--active: var(--form-element--active);
$form-element--empty: var(--form-element--empty);
$form-element--border: var(--form-element--border);

$form-label-font-size: 18px; // 20px;
$form-label-font-weight: 700;
$form-label-font-color: #393939;

$form-input-background-color: #ffffff;
$form-input-font-size: 18px; // 20px;
$form-input-font-weight: 400;
$form-input-font-color: #393939;
$form-input-line-height: 20px;  // Fault with the C.L. designs 24px
$form-input-placeholder-color: #747474;
$form-control-border-weight: 1px;
$form-control-border-color: #747474;
$form-control-padding-y: 15px;
$form-control-padding-x: 15px;

$form-control-desc-color: #747474;
$form-control-desc-size: 18px; // 20px;
$form-control-error-color: var(rgb-1);
$form-control-error-size: 18px; // 20px;