// @import "variables";

.form-panel {
    display: block;
    border-radius: 15px;
    background-color: rgba(#3452e2, 0.1);
    padding: 18px;
    margin-bottom: 20px;
}

.form-panel__header {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 700;
    color: #4d4d4d;
    margin-bottom: 10px;
}

.form-panel__subheader {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #4d4d4d;
    margin-bottom: 10px;
    margin-left: 2px;
}

.form-panel>*:last-child {
    margin-bottom: 0px;
}


.form-panel--flex-column {
    display: flex;
    flex-direction: column;
    padding: 0px;
    overflow: hidden;

    &>* {
        padding: 18px;
    }
}

.form-panel__footer {
    background: rgba(#3452e2, 0.1);
    font-size: 14px;
}