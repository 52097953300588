.field-lock {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $form-element--active;
    color: #ffffff;
    height: 24px;
    width: 24px;
    cursor: help;
}

.field-lock--bordered {
    background-color: #666666;
    outline: 2px solid #ffffff;
    
    &:hover {
        background-color: #222222;
    }
}