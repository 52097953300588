
input[type="radio"] {
    position: absolute;
    visibility: hidden;
}

input[type="radio"]+.radio-switch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
  
}

input[type="radio"]+.radio-switch::before {
    content: " ";
    position: absolute;
    height: 20px;
    width: 20px;
    display: block;
    border-radius: 50%;
    background: $white;
    border: 1px solid $form-element--border;
  
}


// 



input[type="radio"]:checked+.radio-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
}

input[type="radio"]:checked+.radio-switch::before {
    position: absolute;
    content: " ";
    background-color: $form-element--active;
    height: 20px;
    width: 20px;
    display: block;
    border-radius: 50%;
}

input[type="radio"]:checked+.radio-switch::after {
    position: relative;
    content: " ";
    background-color: $white;
    height: 8px;
    width: 8px;
    display: block;
    border-radius: 50%;
}